import React, { useState, useEffect, useCallback, useRef } from "react";
import CustomInputDatePicker from "../../../../../Common/CustomInputDatePicker";
import {
  makeStyles,
  Grid,
  Box,
  CircularProgress,
  Card
} from "@material-ui/core";
import { connect } from "react-redux";


import moment from "moment";
import _ from "lodash";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import TableGenerator from "../common/CollapsibleTableGenerator";
import { useSelector } from "react-redux";
import { ButtonIcon } from "../common/Buttons";
import { PictureAsPdfSharp, GridOn } from "@material-ui/icons";
import { domainLevelConfig } from "ui-config";
import { downloadBlob } from "helpers";
import AsyncAutoComplete from "../common/AsyncAutoComplete";
import { convertUtcToLocal, dateFormatter, getFilterEndDate, getFilterStartDate, getTimeZoneDifference, currencyFormatter } from "utils/helpers";
import { Tooltip } from "@material-ui/core";
import { useSnackbar } from "notistack";

import IconButton from '@material-ui/core/IconButton';

import useProducts from "../../utiles/useProducts";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  graphContainer: {
    height: 310,
    position: "relative",
  },
  selectHeader: {
    padding: "2rem 1rem 0 1rem",
  },
  wrapper: {
    padding: 20,
    backgroundColor: "white",
    borderRadius: "10px"
  },
  noDataMessage: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  toggleWrapper: {
    display: "inline-flex",
    justifyContent: "end",
  },
  navLogoWrapper: {
    height: 74,
    display: "flex",
    alignItems: "left",
    paddingTop: 3,
    flexDirection: "column",
    border: "none",
    background: "none"
  },
  navLogo: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    height: "70px",
    width: "90%",
  },
  logo: {
    padding: 5,
    width: 180,
    height: 70,
  },
  defaultLogo: {
    width: "100px",
    marginLeft: 25,
  },
  root1: {
    width: '100%',
    display: 'inline'
  },
}));

const { productsData } = useProducts();

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow placement={props.place} classes={classes} {...props} />;
}





const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 13,
  },
}));

function Orders(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(
    moment().subtract("days", 15).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());

  /* Dropdown option list */
  const [operatorList, setOperatorList] = useState([
    { value: "all", label: "All" },
  ]);
  const [locationList, setLocationList] = useState([
    { value: "all", label: "All" },
  ]);
  const [areaList, setAreaList] = useState([{ value: "all", label: "All" }]);
  const [vmList, setVmList] = useState([{ value: "all", label: "All" }]);
  const [productList, setProductList] = useState([{ value: "all", label: "All" }]);
  const [statusList, setStatusList] = useState([{ value: "all", label: "All" }]);

  const [query, setQuery] = useState("");

  const [modulesSelected, setModulesSelected] = useState([]);

  const host = useSelector((state) => state.userReducer.host);
  const currentUser = useSelector((state) => state.userReducer.current_user);
  const [orders, setOrders] = useState([]);
  const ref = useRef();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [nextPage, setNextPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [limit, setLimit] = useState(10);
  const [loader, setLoader] = useState(false);
  const [fetchDataParams, setFetchDataParams] = useState({})
  const [vmCount, setVmCount] = useState(0)
  const [ordering, setOrdering] = useState("-order_date");

  /* Dropdown selected values */
  const [selectedOperator, setSelectedOperator] = useState({ value: "all", label: "All" });
  const [selectedLocation, setSelectedLocation] = useState({ value: "all", label: "All" });
  const [selectedArea, setSelectedArea] = useState({ value: "all", label: "All" });
  const [selectedVm, setSelectedVm] = useState({ value: "all", label: "All" });
  const [selectedProduct, setSelectedProduct] = useState({ value: "all", label: "All" });
  const [selectedStatus, setSelectedStatus] = useState({ value: "all", label: "All" });

  /** Dropdown loading state */
  const [operatorListLoading, setOperatorListLoading] = useState(false);
  const [areaListLoading, setAreaListLoading] = useState(false);
  const [locationListLoading, setLocationListLoading] = useState(false);
  const [vmListLoading, setVmListLoading] = useState(false);
  const [productListLoading, setProductListLoading] = useState(false);
  const [statusListLoading, setStatusListLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [enableTaxes, setEnableTaxes] = useState(false);
  const enableTaxesPerVM = useSelector((state) => state.companySettingsReducer.enableTaxesPerVM);

  const user = useSelector((state) => state.userReducer?.current_user);

  const downloadPDF = (order_id) => {
    console.log('Order ID:', order_id);
    
    window.open(`/receipts/${order_id}`, '_blank');
  };

  const setup = () => {
    setLoader(true);
    setOrders([]);
    setModulesSelected([]);
  };

  const handleRes = (data = []) => {
    setOrders(data?.results);
    setLoader(false);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setCount(data.count);
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const collapsibleTableFields = [
    {
      key: "product_id",
      columnName: "Product Id",
    },
    {
      key: "product_sku",
      columnName: "Product SKU",
      render: (val, value) => val?.product_sku ? val?.product_sku : (_.find(productsData, (x) => x.id == val.product_id)?.product_sku || "---"),
    },
    {
      key: "product_name",
      columnName: "Product Name",
    },
    {
      key: "module_number",
      columnName: "Cabinet",
      render: (values) => {
        return values?.module_number ? `Module ${values.module_number?.split('-')[1]}` : "1";
      }
    },
    {
      key: "rack_no",
      columnName: "Rack",
      render: (values) => {
        if (values.row === -1 || values.col === -1) {
          return '---';
        }
        return `${values.row}*${values.col}`;
      }
    },
    {
      key: "quantity",
      columnName: "Quantity",
    },
    {
      key: "product_price",
      columnName: "Price ($)",
      render: (value) => currencyFormatter(value.product_price) ?? "---",
    },
    {
      key: "product_status",
      columnName: "Dispense Status",
    },
    {
      key: "total_amount",
      columnName: "Total Amount ($)",
      render: (value) => currencyFormatter(value.total_amount) ?? "---",
    },
    {
      key: "deals",
      columnName: "Discount Codes",
      render: (value) => value?.deals.map(x => x.discount_code)?.join(', ') || "---"
    },
  ];

  
  const collapsibleTableFields2 = [
    {
      key: "status",
      columnName: "Order Status",
      render: value => value.status ?? '---'
    },
    {
      key: "order_date",
      columnName: "Date and Time",
      render: (value) => dateFormatter(convertUtcToLocal(value.order_date), true) ?? "---",
    }
  ];

  const collapsibleTableFields3 = [
    {
      key: "product_cost",
      columnName: "MRP ($)",
      visible: true,
      render: value => value?.product_cost || value?.product_cost == 0 ? currencyFormatter(value.product_cost) : '---',
    },
    {
      key: "total_discount",
      columnName: "Discount ($)",
      visible: true,
      render: value => currencyFormatter(value.total_discount) ?? '---',
    },
    {
      key: "order_amount",
      columnName: "Order Amount ($)",
      visible: true,
      render: value => currencyFormatter(value.order_amount) ?? '---',
    },
    {
      key: "tax",
      columnName: "Additional Tax ($)",
      visible: enableTaxes,
      render: value => currencyFormatter(value.tax) ?? '---',
    },
    {
      key: "tax_per_vm",
      columnName: "Tax ($)",
      visible: true,
      render: value => currencyFormatter(value.tax_per_vm) ?? '---',
    },
    {
      key: "total_amount",
      columnName: "Total Amount ($)",
      visible: true,
      render: value => currencyFormatter(value.total_amount) ?? '---',
    },
    {
      key: "error_message",
      columnName: "Error Message",
      visible: true,
      render: value => value.error_message ?? "---",
    }
  ];

  const fields = [
    {
      key: "order_id",
      columnName: "ID",
      type: "text",
      visible: true,
      render: (val) => val ?? "---",
    },
    {
      key: "order_status",
      columnName: "Order status",
      type: "text",
      visible: true,
      render: (val) => val ?? "---",
    },
    {
      key: "total_amount",
      columnName: "Amount ($)",
      type: "text",
      visible: true,
      render: (value) => currencyFormatter(value) ?? "---",
    },
    {
      key: "total_order",
      columnName: "No of Items Ordered",
      type: "text",
      visible: true,
      render: (val) => val ?? "---",
    },
    {
      key: "total_success",
      columnName: "No of Items Delivered",
      type: "text",
      visible: true,
      render: (val) => val ?? "---",
    },
    {
      key: "total_failed",
      columnName: "No of Items Failed",
      type: "text",
      visible: true,
      disableSorting: true,
      render: (val) => val ?? "---",
    },
    {
      key: "order_data",
      columnName: "Order Items",
      type: "text",
      disableSorting: true,
      render: (val) => _.join(val, " , ") || "---",
      visible: true,
    },
    {
      key: "user",
      columnName: "User",
      type: "text",
      visible: true,
      render: (val) => val ?? "---",
    },
    {
      key: "location_name",
      columnName: "Location",
      type: "text",
      visible: true,
      render: (val) => val ?? "---",
    },
    {
      key: "vm_name",
      columnName: "Device",
      type: "text",
      visible: true,
      render: (val) => val ?? "---",
    },
    {
      key: "operator_name",
      columnName: "Operator",
      type: "text",
      visible: true,
      render: (val) => val ?? "---",
    },
    {
      key: "order_date",
      columnName: "Placed at",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true) ?? "---",
    },
    {
      key: "data",
      columnName: "E-Receipt",
      type: "text",
      form: false,
      required: false,
      visible: true,
      disableSorting: true,
      render: (_, value) => (
        <>
          <BootstrapTooltip 
          title={"Download E-receipt"}
          >
            <IconButton
              size="small"
              className="m-1"
              variant="contained"
              disabled = {
                !['ITEM_DISPENSING_FAILED_PARTIAL', 'SUCCESSFUL'].includes(value.order_status)
              }
              onClick={
                  () => {
                    downloadPDF(value.order_id)
                  }
              }
            >
              <CloudDownloadIcon />
            </IconButton>
          </BootstrapTooltip>

          <BootstrapTooltip >
            <IconButton
              size="small"
              className="m-1"
              variant="contained"
              disabled = {
                !['ITEM_DISPENSING_FAILED_PARTIAL', 'SUCCESSFUL'].includes(value.order_status)
              }
              style={{ display:'none' }}
              onClick={
                () => {
                  downloadPDF(value.order_id)
                }
            }
            >
              <CloudDownloadIcon />
            </IconButton>
          </BootstrapTooltip>
        </>
      ),
    },
  ];

  const fetchLocations = useCallback(
    async (area_id) => {
      try {
        setLocationListLoading(true);

        if (area_id) {
          const list =
            _.find(areaList, (x) => x.value === area_id)?.locations || [];

          setLocationList([
            { label: "All", value: "all" },
            ..._.map(list, ({ location_id, location_name }) => ({
              label: location_name,
              value: location_id,
            })),

          ]);
        } else {
          if(selectedOperator.value !== 'all') {
            let newLocation = [];
            const locationData=[
              ..._.map(areaList, ({ locations }) => (
                  Array.prototype.push.apply(newLocation,locations)
                )),
              ];
              setLocationList([
                { label: "All", value: "all" },
                ..._.map(newLocation, ({ location_id, location_name }) => ({
                  label: location_name,
                  value: location_id,
                })),
              ]);
            } else {
            const { data } = await window.axiosIns("locations/locations", {
              params: { all: true, state: "all", ordering: "location_name" },
            });
            setLocationList([
              { label: "All", value: "all" },
              ..._.map(data?.results, ({ location_id, location_name }) => ({
                label: location_name,
                value: location_id,
              })),
            ]);
          }
        }

        setSelectedLocation({ label: "All", value: "all" });
      } catch (err) {
        console.log(err);
      } finally {
        setLocationListLoading(false);
      }
    },
    [areaList]
  );

  const fetchOperators = useCallback(async (location) => {
    try {
      setOperatorListLoading(true);

      const { data } = await window.axiosIns("company", {
        params: { all: true, location, ordering: "business_name" },
      });

      const list = data?.data?.results;

      const dropdownMap = [
        { value: "all", label: "All" },
        ..._.map(list, ({ id, business_name }) => ({
          label: business_name,
          value: id,
        })),

      ]

      setOperatorList(dropdownMap);

      if (user.type !== "SA") {
        if (list?.length) {
          setSelectedOperator(dropdownMap.find(x => x.value === user.company.company_id));
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setOperatorListLoading(false);
    }
  }, []);

  const fetchVM = useCallback(async (params = {lite:true}) => {
    try {
      setVmListLoading(true);
      setVmCount((val)=> val+1)
      const { data } = await window.axiosIns("device", {
        params: { all: true, ordering: "vm_name", lite:true, ...params },
      });
      setVmList([
        { label: "All", value: "all" },
        ..._.map(data?.data?.results, ({ id, serial_number, vm_name }) => ({
          label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
          value: id,
        })),

      ]);
      setSelectedVm({ value: "all", label: "All" });
    } catch (err) {
      console.log(err);
      setVmListLoading(false);
    } finally {
      setVmCount((val) => {
        if(val-1 <= 0) setVmListLoading(false);
        return val -1
      })
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    try {
      setProductListLoading(true);
      const { data } = await window.axiosIns("products", {
        params: { all: true, ordering: "product_name" },
      });
      setProductList([
        { label: "All", value: "all" },
        ..._.map(data?.results, ({ id, product_name }) => ({
          label: product_name,
          value: id,
        })),

      ]);
      setSelectedProduct({ value: "all", label: "All" });
    } catch (err) {
      console.log(err);
      setProductListLoading(false);
    } finally {
      setProductListLoading(false);
    }
  }, []);

  const fetchAreas = useCallback(async (operator_id) => {
    try {
      setAreaListLoading(true);

      const params = { all: true, ordering: "area_name" };
      if (operator_id) params.operator_id = operator_id;

      const { data } = await window.axiosIns("locations/areas", {
        params,
      });

      setAreaList([
        { label: "All", value: "all" },
        ..._.map(data?.results, ({ area_id, area_name, locations }) => ({
          label: area_name,
          value: area_id,
          locations,
        })),

      ]);
      setSelectedArea({ label: "All", value: "all" });
    } catch (err) {
      console.log(err);
    } finally {
      setAreaListLoading(false);
    }
  }, []);

  const fetchStatus = useCallback(async () => {
    try {
      setStatusListLoading(true);

      const params = { all: true };

      const { data } = await window.axiosIns("orders/order_status", {
        params,
      });

      setStatusList([
        { label: "All", value: "all" },
        ..._.map(data?.data, ({ key, label }) => ({
          label: label,
          value: key
        }))
      ]);
      setSelectedStatus({ label: "All", value: "all" });
    } catch (err) {
      console.log(err);
    } finally {
      setStatusListLoading(false);
    }
  }, []);

  const fetchExcel = async () => {

    if (count > 35000) {
      enqueueSnackbar("Number of Records can not be more than 35000 for Excel download. Please lower the date range.", {autoHideDuration: 4000});
      return;
    }

    setLoader(true);
    const params = {
      // refine: true,
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      tz: getTimeZoneDifference(),
      _scope: "AND"
    }

    if (selectedVm.value && selectedVm.value !== "all") {
      params["device_id"] = selectedVm.value;
    } 
    if (selectedLocation.value && selectedLocation.value !== "all") {
      params["device__location_id"] = selectedLocation.value;
    } 
    if (selectedArea.value && selectedArea.value !== "all") {
      params["device__location__area_id"] = selectedArea.value;
    } 
    if (selectedOperator.value && selectedOperator.value !== "all") {
      params["device__company_id"] = selectedOperator.value;
    } 
    if (selectedProduct.value && selectedProduct.value !== "all") {
      params["products"] = selectedProduct.value;
    }
    if(selectedStatus?.value && selectedStatus.value !== "all"){
      params["status"] = selectedStatus.value;
    }

    try {
      const {data} = await window.axiosIns("orders/export_data", {
        responseType: 'blob',
        params,
      });
  
      if (data) {
        downloadBlob(new Blob([data], { type: 'application/xlsx' }), `Orders-Summary-${moment().format("MM-DD-YYYY")}.xlsx`);
      }
  
      setLoader(false);
    } catch(err) {
      setLoader(false);
    }
  }

  const fetchPDF = async () => {

    if (count > 15000) {
      enqueueSnackbar("Number of Records can not be more than 15000 for PDF download. Please lower the date range.", {autoHideDuration: 4000});
      return;
    }

    setLoader(true);
    const params = {
      // refine: true,
      model: "order",
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      type: 'pdf',
      tz: getTimeZoneDifference(),
      _scope: "AND"
    }

    if (selectedVm.value && selectedVm.value !== "all") {
      params["device_id"] = selectedVm.value;
    } 
    if (selectedLocation.value && selectedLocation.value !== "all") {
      params["device__location_id"] = selectedLocation.value;
    } 
    if (selectedArea.value && selectedArea.value !== "all") {
      params["device__location__area_id"] = selectedArea.value;
    } 
    if (selectedOperator.value && selectedOperator.value !== "all") {
      params["device__company_id"] = selectedOperator.value;
    } 
    if (selectedProduct.value && selectedProduct.value !== "all") {
      params["products"] = selectedProduct.value;
    }
    if(selectedStatus?.value && selectedStatus.value !== "all"){
      params["status"] = selectedStatus.value;
    }

    try {
      const {data} = await window.axiosIns("orders/export", {
        responseType: 'blob',
        params,
      });
  
      if (data) {
        downloadBlob(new Blob([data]),  `Orders-Summary-${moment().format("MM-DD-YYYY")}.pdf`);
      }
  
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  }

  const fetchOrder = async (order, pageNumber, limit) => {

    setLimit(limit);
    const realPage = pageNumber ? 1 : page + 1
    const params = {
      ordering:order,
      page: realPage,
      limit: limit,
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      history:true,
      latest: true,
      _scope: "AND"
    }

    if (selectedVm.value && selectedVm.value !== "all") {
      params["device_id"] = selectedVm.value;
    } 
    if (selectedLocation.value && selectedLocation.value !== "all") {
      params["device__location_id"] = selectedLocation.value;
    } 
    if (selectedArea.value && selectedArea.value !== "all") {
      params["device__location__area_id"] = selectedArea.value;
    } 
    if (selectedOperator.value && selectedOperator.value !== "all") {
      params["device__company_id"] = selectedOperator.value;
    } 
    if (selectedProduct.value && selectedProduct.value !== "all") {
      params["products"] = selectedProduct.value;
    }
    if (selectedStatus.value && selectedStatus.value !== "all") {
      params["status"] = selectedStatus.value;
      delete params.latest
    }

    if (_.isEqual(params, fetchDataParams)) {
      return;
    }

    setFetchDataParams(params);
    setLoader(true);

    try {
      const { data } = await window.axiosIns("orders", {
        params,
      });
  
      setCount(data.count);
      handleRes(data)
  
      setOrders(data.results);
      setLoader(false);
    } catch(err) {
      setLoader(false);
    }
  }

  useEffect(() => {
    if(selectedOperator && selectedOperator.value === "all") {
      fetchAreas();
      fetchVM();
    }
  }, [selectedOperator]);



  useEffect(() => {
    setEnableTaxes(enableTaxesPerVM);
  }, [enableTaxesPerVM]);

  useEffect(() => {
    if(selectedArea.value === "all") {
      fetchLocations();
    }
  }, [selectedArea]);

 useEffect(() => {
  if (selectedLocation.value !== "all") {
    fetchVM({ location: selectedLocation.value });
  }
}, [selectedLocation]);
useEffect(() => {
  if (selectedArea.value !== "all") {
    fetchVM({ area_id: selectedArea.value });
  }
}, [selectedArea]);
useEffect(() => {
  if (selectedOperator.value !== "all") {
    fetchVM({ company_id: selectedOperator.value });
  }
}, [selectedOperator]);


  useEffect(() => {
    fetchOperators();
    fetchProducts();
    fetchStatus();
  }, [])

  useEffect(() => {
    fetchOrder(ordering, page, limit);
  }, [selectedLocation,
      selectedVm,
      selectedArea,
      selectedOperator,
      selectedProduct,
      selectedStatus,
      startDate,
      endDate,
    ]);

  useEffect(() => {
    if (selectedOperator && selectedOperator.value !== "all") {
      fetchAreas(selectedOperator.value);
    }
  }, [selectedOperator, fetchAreas]);

  useEffect(() => {
    if (selectedArea.value && selectedArea.value !== "all") {
      fetchLocations(selectedArea.value);
    }
  }, [selectedArea, fetchLocations]);

  const hostConfig =
    domainLevelConfig[host] || domainLevelConfig["default"] || {};

    const getWidth = () => {
      let width = 0;

      const allQty = [selectedOperator, selectedArea, selectedLocation].reduce((acc, val) => val === "all" ? acc + 1 : acc, 0);

      if(allQty === 3) {
        width = 1490;
      } else if(allQty === 2) {
        width = 1380;
      } else {
        width = 1280;
      }

      return width;
    };

    const tableWidth = getWidth();

  const truncate = (str) => (str.length > 12) ? str.substr(0, 12-1) + '...' : str

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>

      <Grid spacing={4} container>
        <Grid className={classes.selectHeader} spacing={2} container xs={12}>
          <Grid item style={{maxWidth: "12.5%", flex: "0 0 12.5%"}}>
              <CustomInputDatePicker
                value={startDate}
                onChange={(date) => {
                  setStartDate(date)
                  setPage(0)
                }}
                className="mr-2"
                label="Start Date"
                maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}
                disabled={operatorListLoading || areaListLoading || locationListLoading || vmListLoading || productListLoading}
              />
          </Grid>
          <Grid item style={{maxWidth: "12.5%", flex: "0 0 12.5%"}}>
              <CustomInputDatePicker
                value={endDate}
                onChange={(date) => {
                  setEndDate(date)
                  setPage(0)
                }}
                label="End Date"
                minDate={new Date(startDate)}
                maxDate={new Date()}
                disabled={operatorListLoading || areaListLoading || locationListLoading || vmListLoading || productListLoading}
              />
          </Grid>
          <Grid item style={{maxWidth: "12.5%", flex: "0 0 12.5%"}}>
            <AsyncAutoComplete
              onChange={(val) => { 
                setSelectedOperator(val);
                setPage(0)
              }}
              value={selectedOperator}
              loading={operatorListLoading}
              disabled={operatorListLoading || areaListLoading || locationListLoading || vmListLoading || productListLoading}
              options={operatorList}
              required
              label="Operator"
            />
          </Grid>
          <Grid item style={{maxWidth: "12.5%", flex: "0 0 12.5%"}}>
            <AsyncAutoComplete
              onChange={(val) => {
                setSelectedArea(val);
                setPage(0)
              }}
              loading={areaListLoading}
              value={selectedArea}
              options={areaList}
              required
              label="Area"
              disabled={operatorListLoading || areaListLoading || locationListLoading || vmListLoading || productListLoading}
            />
          </Grid>
          <Grid item style={{maxWidth: "12.5%", flex: "0 0 12.5%"}}>
            <AsyncAutoComplete
              onChange={(val) => {
                setSelectedLocation(val);
                setPage(0)
              }}
              value={selectedLocation}
              loading={locationListLoading}
              options={locationList}
              required
              label="Location"
              disabled={operatorListLoading || areaListLoading || locationListLoading || vmListLoading || productListLoading}
            />
          </Grid>
          <Grid item style={{maxWidth: "12.5%", flex: "0 0 12.5%"}}>
            <AsyncAutoComplete
              onChange={(val) => {
                  setSelectedVm(val);
                  setPage(0)
              }}
              options={vmList}
              loading={vmListLoading}
              value={selectedVm}
              required
              label="Vending Machine"
              disabled={operatorListLoading || areaListLoading || locationListLoading || vmListLoading || productListLoading}
            />
          </Grid>
          <Grid item style={{maxWidth: "12.5%", flex: "0 0 12.5%"}}>
            <AsyncAutoComplete
              onChange={(val) => {
                  setSelectedProduct(val);
                  setPage(0)
              }}
              options={productList}
              value={selectedProduct}
              loading={productListLoading}
              required
              label="Products"
              disabled={operatorListLoading || areaListLoading || locationListLoading || vmListLoading || productListLoading}
            />
          </Grid>
          <Grid item style={{maxWidth: "12.5%", flex: "0 0 12.5%"}}>
            <AsyncAutoComplete
              onChange={(val) => {
                  setSelectedStatus(val);
              }}
              options={statusList}
              value={selectedStatus}
              loading={statusListLoading}
              required
              label="Order Status"
            />
          </Grid>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Card style={{ margin: "0px 0px 20px 0px", padding: 10 }} variant="outlined">
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <ButtonIcon
                className="mr-3"
                label="Download PDF"
                size="medium"
                Icon={PictureAsPdfSharp}
                onClick={() => {fetchPDF()}}
                disabled={loading || orders?.length <= 0}
              />
              <ButtonIcon
                className="mr-3"
                label="Download Excel"
                size="medium"
                Icon={GridOn}
                onClick={() => {fetchExcel()}}
                disabled={loading || orders?.length <= 0}
              />
            </Box>
          </Box>
        </Card>
      </Box>

      <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={orders}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            fetchOrder(ordering,page,limit);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            setRowsPerPage(rows);
            setLimit(rows)
            setPage(0);
            fetchOrder(ordering, page, rows);
            
          }}
          dataCount={count}
          onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          collapsible={true}
          collapsibleHeader={"Order Details"}
          collapsibleFields={collapsibleTableFields}
          collapsibleFieldKey={"order_items"}
          collapsibleHeader2={"Order History"}
          collapsibleFields2={collapsibleTableFields2}
          collapsibleFieldKey2={"history"}
          collapsibleFieldKey3={"order_summery"}
          collapsibleHeader3={"Order Summary"}
          collapsibleFields3={collapsibleTableFields3}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
    appLogo: state.userReducer.appLogo,
  };
};

export default connect(mapStateToProps)(Orders);


