import { get, post, put, patch, httpDelete } from "../HttpService";

export class ModuleService {
  static modules = (queryData = {}, config = {}) => {
    return get(`device`, queryData, config);
  };
  static search = (queryData = {}, config = {}) => {
    return get(`device`, queryData, config);
  };
  static addModule = (queryData = {}, config = {}) => {
    return post(`device`, queryData, config);
  };
  static fetchPasscode = (queryData = {}, config = {}) => {
    return get(`device/${queryData}/fetch_device_pass_code`, queryData, config);
  };
  static resetPasscode = (deviceId, config = {}) => {
    return patch(`device/${deviceId}`, { pass_code: null }, config);
  };
  static editModule = (companyId, queryData = {}, config = {}) => {
    return put(`device/${companyId}`, queryData, config);
  };
  static upgradeModule = ( queryData = {}, config = {}) => {
    return put(`/device/upgrade_device`, queryData, config);
  };
  static deleteModule = (lampId, queryData = {}) => {
    return httpDelete(`device/${lampId}`, queryData);
  };
  static advancedSearch = (queryData = {}, config = {}) => {
    return get(`device/search_device`, queryData, config);
  };
  static bulkEdit = (queryData = {}, config = {}) => {
    return post(`device/bulk_edit`, queryData, config);
  };
  static updateStatus = (queryData = {}, config = {}) => {
    return put(`device/update_approval_status`, queryData, config);
  };
}
